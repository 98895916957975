import { defineStore } from 'pinia';

interface IConfig {
  isOpen?: boolean;
}
export const useSearchStore = defineStore({
  id: "search",

  state: () => {
    return {
      isOpen: false,
    }
  },

  actions: {
    changeOpen: function (val: boolean) {
      this.isOpen = val;
    }
  },
});
