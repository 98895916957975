<template>
  <div
    class="root-layout"
    :class="`template-${ templateVersion }`"
  >
    <div class="root-layout__header">
      <Header />
    </div>

    <div class="root-layout__body">
      <slot />
    </div>

    <div class="root-layout__footer">
      <Footer />
    </div>

    <PopUpSearch v-if="isPopUpSearch"/>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import { useSearchStore } from '~/store/search';
import { useSmsUser } from '~/store/sms-user';

const {
  $config
} = useNuxtApp();

const templateVersion: ComputedRef<string> = computed(() => {
  const versionTemplate = $config?.public?.templateVersion;
  return String(versionTemplate || '1')
});

const HeaderV1 = defineAsyncComponent(() => import('~/components/layout/HeaderV1.vue'));
const HeaderV2 = defineAsyncComponent(() => import('~/components/layout/HeaderV2.vue'));
const HeaderV3 = defineAsyncComponent(() => import('~/components/layout/HeaderV3.vue'));
const Header: any = computed(() => {
  if (templateVersion.value === '2') {
    return HeaderV2
  }
  if (templateVersion.value === '3') {
    return HeaderV3
  }
  return HeaderV1
});

const FooterV1 = defineAsyncComponent(() => import('~/components/layout/FooterV1.vue'));
const FooterV2 = defineAsyncComponent(() => import('~/components/layout/FooterV2.vue'));
const FooterV3 = defineAsyncComponent(() => import('~/components/layout/FooterV3.vue'));
const Footer: any = computed(() => {
  if (templateVersion.value === '2') {
    return FooterV2
  }
  if (templateVersion.value === '3') {
    return FooterV3
  }
  return FooterV1
});

const searchStore = useSearchStore();
const PopUpSearchV2 = defineAsyncComponent(() => import('~/components/search/PopUpSearchV2.vue'));
const PopUpSearch: any = computed(() => {
  if (templateVersion.value === '2') {
    return PopUpSearchV2
  }
  return PopUpSearchV2
});
const isPopUpSearch: ComputedRef<boolean> = computed(() => {
  return searchStore.isOpen
});

const smsUser = useSmsUser();
const sitisAuth = useSitisAuth();
watch(() => sitisAuth.isLoggedIn.value, async () => {
  await smsUser.getBalance();
});
</script>

<style lang="scss" scoped>
.root-layout {
  .root-layout__body {
    padding-top: 135px;
  }
  &.template-2 .root-layout__body {
    padding-top: 180px;
  }
}
.root-layout__body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: calc(100vh - 110px);
}

@media (max-width: 1199px) {
  .root-layout {
    &.template-2 .root-layout__body {
      padding-top: 159px;
    }
  }
}
@media (max-width: 1023px) {
  .root-layout {
    &.template-2 .root-layout__body {
      padding-top: 100px;
    }
  }
}
</style>
